<template>
  <div class="form-group" v-if="field.id">
    <qrcode-stream v-if="field.qr && showQr" :ref="`qr-${field.id}`" @detect="onQRDetect($event, field.id)" @init="onQRInit($event, field.id)" />
    <label v-if="field.name && field.type !== 'toggle'" :for="field.id">{{ field.name }}</label>
    <span v-if="field.string">{{ field.string }}</span>
    <place-autocomplete-field v-else-if="field.place" v-model="field.value"
      :api-key="apiKey"
      :types="field.types || false"
      :componentRestrictions="field.componentRestrictions || { country: 'US' }"
      :placeholder="field.placeholder"
      @autocomplete-select="onPlace(field.id, arguments[0], arguments[1])"
    />
    <component v-else-if="field.component" :is="field.component" :value="field.value" :options="field.options" @change="field.value = $event" />
    <textarea v-else-if="field.text" class="form-control" :id="field.id"  v-model="field.value" :rows="3" />
    <suggest-multi v-else-if="field.type === 'suggestMulti'" :value="field.value" :options="field.options" @change="field.value = $event" :importFn="field.importFn" :groupLabel="field.groupLabel" :groupValues="field.groupValues" :groupSelect="field.groupSelect" :searchable="field.searchable" :placeholder="field.placeholder" :importLabel="field.importLabel" :importTooltip="field.importTooltip" :ref="field.ref || 'sm'" />
    <suggest v-else-if="field.list" v-model="field.value"
      :ref="field.id"
      :list="field.list" :freeform="field.freeform" :regex="field.regex"
      :regexTruncateLength="field.regexTruncateLength"
      :mode="field.mode"
      :minLength="field.minLength"
      @change="field.value = $event" :qr="field.qr" @qr="showQr = !showQr" />
    <tree-select v-else-if="field.type === 'tree'" :multiple="field.multiple" :options="field.options" :value="field.value" @input="field.value = $event" :searchable="field.searchable" :closeOnSelect="field.closeOnSelect" :defaultExpandLevel="field.expandLevel" />
    <d-select v-else-if="field.options" :value="field.value" :options="field.options" :disabled="field.disabled" @input="onSelectInput($event, field.id)" @change="field.onChange" :required="field.required"/>
    <zone-select v-else-if="field.type === 'location'" :editable="field.editable" :from="field.from" :noBranches="field.noBranches" :value="field.value" @input="field.value = $event" :maxLevel="field.maxLevel" :multiple="field.multiple" :showRoot="!field.hideRoot" :lazyLoadRoot="field.lazyLoadRoot" :ref="field.ref || 'zs'" />
    <zone-picker v-else-if="field.type === 'location-picker'" :value="field.value" @input="field.value = $event" :maxLevel="field.maxLevel" />
    <phrase-generator v-else-if="field.type === 'generator'" v-model="field.value" :label="field.name" :disabled="field.disabled" @change="field.value = $event" />
    <toggle v-else-if="field.type === 'toggle'" v-model="field.value" :label="field.name" :tooltip="field.tooltip" :disabled="field.disabled" />
    <radio-list v-else-if="field.radioList" :radioList="field.radioList" :value="field.value" @input="field.value = $event" />
    <d-input v-else-if="field.id"
      :id="field.id"
      :ref="field.id"
      v-model="field.value"
      :type="field.type || 'text'"
      :placeholder="field.placeholder"
      :disabled="field.disabled"
      :readonly="field.readonly"
      :required="field.required"
      v-d-tooltip.hover :title="field.tooltip"
    />
  </div>
  <label v-else-if="field.name">{{ field.name }}</label>
  <div v-else-if="field.nameMarkup" v-html="field.nameMarkup"></div>
</template>

<script>
import { QrcodeStream } from '@vpulim/vue-qrcode-reader'
import config from '@/config'

export default {
  name: 'edit-field',
  props: ['field'],
  data () {
    return {
      apiKey: config.google.apiKey,
      showModal: false,
      showQr: false
    }
  },
  components: {
    QrcodeStream
  },
  methods: {
    async onQRDetect (promise, id) {
      try {
        let { content: value } = await promise
        value = ((value || '').match(/([a-fA-F0-9]{12}$)/) || [])[0]
        this.field.value = typeof value === 'string' ? value.toLowerCase() : value
        if (value) {
          this.field = { ...this.field, value: value.toLowerCase() }
          if (this.field.autoClose) {
            this.showQr = false
          }
        }
      } catch (error) {
        alert(`QR Scanner Error: ${error.name}`)
      }
    },
    async onQRInit (promise, id) {
      try {
        await promise
        const qr = this.$refs[`qr-${id}`]
        qr.height = qr.width
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          alert('You need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          alert('No camera on this device')
        } else if (error.name === 'NotSupportedError') {
          alert('Secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          alert('The camera is already in use')
        } else if (error.name === 'OverconstrainedError') {
          alert('No suitable camera found')
        } else if (error.name === 'StreamApiNotSupportedError') {
          alert('This browser does not support QR Scanning')
        }
      }
    },
    onPlace (id, place, result) {
      const businessName = (place.description || '').split(',')[0]
      const address = result.formatted_address
      if (businessName.startsWith(result.address_components[0].long_name)) {
        this.field.value = address
      } else {
        this.field.value = `${businessName}, ${address}`
      }
    },
    onSelectInput (input, id) {
      this.field.value = input
      if (typeof this.field.input === 'function') {
        this.field.input(input)
      }
    }
  }
}
</script>

<style>
.qrcode-stream-wrapper {
  margin-bottom: 20px;
  height: 200px !important;
}
</style>
